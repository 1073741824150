<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="changeName($event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.slug"
            :external-errors="errors['slug']"
            :label-form="'Slug' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.slug"
            :disabled="!formEditable"
            @change="$set(form, 'slug', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PHONE_TYPOLOGY_TYPES } from './store'

import slugify from 'slugify'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'PhoneTypologyEdit',
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'Phone typology',
      rolePerm: 'organization_phonetypology_retrieve',
      editRolePerm: 'organization_phonetypology_update'
    }
  },
  computed: {
    ...mapGetters({
      ...PHONE_TYPOLOGY_TYPES.GENERIC.organization.phonetypology.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...PHONE_TYPOLOGY_TYPES.GENERIC.organization.phonetypology.DETAIL.ACTIONS
    }),
    changeName (evt) {
      this.$set(this.form, 'name', evt)
      if (this.formEditable) {
        this.setSlug()
      }
    },
    setSlug () {
      this.$v.form.name.$touch()
      if (this.form.name) {
        this.$set(this.form, 'slug', slugify(this.form.name, { remove: /[*+~.()'"!:@]/g }))
      } else {
        this.$set(this.form, 'slug', undefined)
      }
    }
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      slug: { required }
    }
  }
}
</script>
